<template lang="pug">
    aside#sobreGaleriaCards
        .wrapper
            .galeria
                ul
                    li
                        h4 Junho - 2018
                        h3 Fundação da GT Building

                        .conteudo
                            p
                                | Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment. Bring to the table win-win survival strategies to ensure proactive domination. 
                            p
                                | value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.

                        figure
                            img(:src="require('@/assets/images/tmp/galeria_sobre01.png')", alt="Fundação da GT Building")
                    li
                        h4 Junho - 2018
                        h3 Fundação da GT Building

                        .conteudo
                            p
                                | Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment. Bring to the table win-win survival strategies to ensure proactive domination. 
                            p
                                | value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.

                        figure
                            img(:src="require('@/assets/images/tmp/galeria_sobre01.png')", alt="Fundação da GT Building")
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped src="./SobreGaleriaCards.styl"></style>