<template lang="pug">
    article#sobre
        .wrapper
            div.resumo
                h3 NEGÓCIOS INTELIGENTES
                p 
                    | Um negócio inteligente é mais seguro, por mais desafiador que seja. 
                    | É uma oportunidade que ganha vida quando o capital investido não 
                    | é simplesmente financeiro, é humano.

        div.galeria(ref="galeria")
            ul
                li(@click="maisInfo('fundador', false)").geninho.real
                    img(:src="conteudo.legado.fundador.imagem")
                    p.nome Fundador
                li(ref="card", @click="maisInfo('sucessao', false)").geninho
                    img(:src="conteudo.legado.sucessao.imagem")
                    p.nome Sucessão
                li.intervalo
                    p.nome
                        |Linha do tempo 
                li(@click="maisInfo(index)", v-for="item, index in conteudo.historia")
                    img(:src="item.imagemCard", :alt="`${item.data.mes} - ${item.data.ano} | ${item.texto.titulo}`").itemImg
                    div.texto
                        // p.epoca {{ item.data.mes }} - {{ item.data.ano }}
                        p.epoca {{ item.data.ano }}
                        p.evento {{ item.texto.titulo }}
</template>

<script>
import SobreGaleriaCards from '@/components/SobreGaleriaCards/SobreGaleriaCards.vue';

export default {
    components: {
        SobreGaleriaCards
    },
    data() {
        return {
            galeriaAtivo: 0,
            conteudo: {
                legado: {
                    fundador: {
                        texto: {
                            titulo: "DR. GENINHO THOMÉ",
                            textos: [
                                "Fundador e Presidente da GT Company. Idealizador do projeto que busca, em cada negócio, um propósito de ser."
                            ]
                        },
                        imagem: require('@/assets/images/legado/geninho.png')
                    },
                    sucessao: {
                        texto: {
                            titulo: "JOÃO ALFREDO THOMÉ",
                            textos: [
                                "A longevidade da forma de trabalhar ganha vida no aprendizado diário, que se refina na convivência entre mestre e aprendiz, entre pai e filho."
                            ]
                        },
                        imagem: require('@/assets/images/legado/joaoalfredo.png')
                    }
                },
                historia: [
                    {
                        data: {
                            // mes: "Agosto",
                            ano: 1990
                        },
                        texto: {
                            titulo: "O INÍCIO",
                            textos: [
                                "Dr. Geninho Thomé começou a realizar estudos científicos para desenvolver implantes a um preço mais acessível. Nascia o que viria a transformá-lo em uma referência mundial. Dr. Geninho Thomé passou a desenvolver artesanalmente modelos de implantes em titânio."
                            ]
                        },
                        imagem: require('@/assets/images/mobile/1990-1.png'),
                        imagemCard: require('@/assets/images/historia/1990-1.png')
                    },
                    {
                        data: {
                            // mes: "Agosto",
                            ano: 1993
                        },
                        texto: {
                            titulo: "FUNDAÇÃO DA NEODENT",
                            textos: [
                                "Dr. Geninho Thomé funda a Neodent, empresa que revolucionou o mercado dentário com implantes de alta qualidade a preço acessível para todos."
                            ]
                        },
                        imagem: require('@/assets/images/mobile/1993-2.png'),
                        imagemCard: require('@/assets/images/historia/1993-2.png')
                    },
                    {
                        data: {
                            // mes: "Agosto",
                            ano: 2004
                        },
                        texto: {
                            titulo: "FUNDAÇÃO DA ILAPEO",
                            textos: [
                                "Dr. Geninho Thomé foi um dos fundadores do Instituto Latino Americano de Pesquisa e Ensino Odontológico. A ILAPEO é consequência dos cursos ministrados por ele desde 1993 e nasceu como braço de pesquisa e ensino odontológico da Neodent."
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2004-1.png'),
                        imagemCard: require('@/assets/images/historia/2004-1.png')
                    },
                    {
                        data: {
                            // mes: "Agosto",
                            ano: 2004
                        },
                        texto: {
                            titulo: "A NEOORTHO",
                            textos: [
                                "No mesmo ano, criou a Neoortho, fabricante de próteses ortopédicas e produtos para osteossíntese. Hoje, a companhia possui um complexo industrial com 2.000 m² em Curitiba/PR e duas sedes comerciais: uma internacional em Fort Myers/EUA e outra na capital paranaense.",
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2004-2.png'),
                        imagemCard: require('@/assets/images/historia/2004-2.png')
                    },
                    {
                        data: {
                            // mes: "Agosto",
                            ano: 2012
                        },
                        texto: {
                            titulo: "Venda parcial da Neodent",
                            textos: [
                                "Ao constatar o sucesso da Neodent, a Straumann, maior player do mercado de implantes do mundo, busca pela família Thomé e adquire 49% das ações da empresa.",
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2012.png'),
                        imagemCard: require('@/assets/images/historia/2012.png')
                    },
                    {
                        data: {
                            // mes: "Julho",
                            ano: 2013
                        },
                        texto: {
                            titulo: "NASCE O FAMILY OFFICE",
                            textos: [
                                "Dr. Geninho Thomé quis aplicar toda a inteligência utilizada na Neodent para ajudar as pessoas em negócios de outros setores. Por isso, criou a Lavoro: uma holding que abrange negócios próprios e parcerias, que mais tarde passaria a se chamar GT Company.",
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2013.png'),
                        imagemCard: require('@/assets/images/historia/2013.png')
                    },
                    {
                        data: {
                            // mes: "Junho",
                            ano: 2015
                        },
                        texto: {
                            titulo: "Venda total da Neodent",
                            textos: [
                                "Com um trabalho sério e percebendo que seu legado estava em boas mãos, Dr. Geninho Thomé aceita a aquisição total da Neodent. Mesmo assim, manteve-se no cargo de presidente científico da empresa.",
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2015.png'),
                        imagemCard: require('@/assets/images/historia/2015.png')
                    },
                    {
                        data: {
                            // mes: "Junho",
                            ano: 2019
                        },
                        texto: {
                            titulo: "NASCE A GT COMPANY",
                            textos: [
                                "A concretização do trabalho do Dr. Geninho Thomé veio através do nascimento da GT Company, um escritório que não é apenas investidor: é parceiro de todos os negócios em que atua.",
                            ]
                        },
                        imagem: require('@/assets/images/mobile/2019.png'),
                        imagemCard: require('@/assets/images/historia/2019.png')
                    },
                ]
            }
        }
    },
    mounted() {
        let gt_cards = localStorage.getItem('gt_cards');
        if (gt_cards) {
            if (JSON.parse(gt_cards).galeriaAtivo == 'fundador')
                this.galeriaAtivo = 0;
            else if (JSON.parse(gt_cards).galeriaAtivo == 'sucessao')
                this.galeriaAtivo = 1;
            else
                this.galeriaAtivo = JSON.parse(gt_cards).galeriaAtivo + 3;

            localStorage.removeItem('gt_cards');
        }
        
        this.$refs.galeria.scrollLeft = (this.$devinho.getWidth(this.$refs.card) + (this.$devinho.getMargin(this.$refs.card) * 2)) * this.galeriaAtivo
    },
    methods: {
        maisInfo(index, isHistoria = true) {
            localStorage.setItem('gt_cards', JSON.stringify({
                galeriaAtivo: index
            }))

            if(isHistoria) 
                this.$router.push({
                    name: 'cards',
                    params: {
                        lista: this.conteudo.historia,
                        ativo: index
                    }
                })
            else
                this.$router.push({
                    name: 'cards',
                    params: {
                        lista: [this.conteudo.legado[index]],
                        ativo: 0
                    }
                })
        }
    }
}
</script>

<style lang="stylus" scoped src="./Sobre.styl"></style>